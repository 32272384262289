// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll(
  'video[playsinline]'
) as NodeListOf<HTMLVideoElement>

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

if (window.matchMedia('(min-width: 1024px)').matches) {
  const navLinks = document.querySelectorAll<HTMLElement>('.menu-circle')
  const sections = document.querySelectorAll<HTMLElement>('section')
  const footer = document.getElementById('footer')
  const menu = document.querySelector<HTMLElement>('.menu')

  const removeActiveClasses = () => {
    navLinks.forEach((link) => {
      link.classList.remove('active')
      const svgCircle = link.querySelector('svg circle')
      if (svgCircle) {
        svgCircle.setAttribute('fill', '#C3C3C3')
      }
    })
  }

  navLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault()

      removeActiveClasses()

      link.classList.add('active')

      const svgCircle = link.querySelector('svg circle')
      if (svgCircle) {
        svgCircle.setAttribute('fill', '#ff0000')
      }

      const targetId = link.getAttribute('href')?.substring(1)
      const targetSection = document.getElementById(targetId || '')

      if (targetSection) {
        targetSection.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    })
  })

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.getAttribute('id')

          removeActiveClasses()

          const activeLink = document.querySelector<HTMLElement>(`a[href="#${sectionId}"]`)
          if (activeLink) {
            activeLink.classList.add('active')
          }
        }
      })
    },
    {
      threshold: 0.6
    }
  )

  sections.forEach((section) => {
    observer.observe(section)
  })

  if (footer && menu) {
    const footerObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            menu.classList.add('active')
          } else {
            menu.classList.remove('active')
          }
        })
      },
      {
        threshold: 0.1
      }
    )

    footerObserver.observe(footer)
  }
}

const scrollBtn = document.querySelector('.scroll-btn')
const nextSection = document.getElementById('vision')

if (scrollBtn && nextSection) {
  scrollBtn.addEventListener('click', () => {
    nextSection.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  })
}

window.addEventListener('load', () => {
  document.body.classList.add('page-loaded')
})

const svgElement = document.querySelector<SVGElement>('.color-adjust-svg')
const sections = document.querySelectorAll<HTMLElement>('section')

function adjustSvgColor(section: HTMLElement): void {
  const bgColor = window.getComputedStyle(section).backgroundColor

  if (bgColor === 'rgb(0, 0, 0)') {
    svgElement?.style.setProperty('fill', '#ffffff')
  } else {
    svgElement?.style.setProperty('fill', '#000000')
  }
}

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        adjustSvgColor(entry.target as HTMLElement)
      }
    })
  },
  {
    threshold: 0.8
  }
)

sections.forEach((section) => {
  observer.observe(section)
})

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */
